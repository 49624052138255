<template>
  <div class="process-data-link">
    <div class="status-data-details">
      <span class="text-status-details" :class="colorStatusDataDetails()" >{{statusDataDetails()}}</span>
    </div>
    <div class="process-data-title" v-if="titlePages">
      <div class="warp-title">
        <h1 class="wrap-title-text">{{ titlePages }}</h1>
      </div>
    </div>
    <div 
      class="category-table main-table custom-table" 
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <DataTable
        :data-source="listPrimaryGrid"
        :grid-columns="listPrimaryColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :isEmptySource="isEmptySource"
        :labelEmpty="labelEmpty"
        :dataPattern="dataPattern"
        :allowAddNew="false"
        :isShowToolbar="true"
        :showFocus="false"
        :isResizeCustome="false"
        :showDelete="false"
        :isReadOnlyTitle="true"
        :isHasData="isHasData"
        :isDisableBySetting="false"
        @changeFullScreen="onChangeFullScreen"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
        @onHandleActionTable="onHandleActionTable"
      />
    </div>
    <ApprovalPopup
      :dialog="dialogApproval"
      :items="listStatusApproval"
      :confirmText="$t('register_data.button_decision')"
      @submit="approvalDataHandler"
      @close="dialogApproval = false"
    />
    <QuestionPopup
      :dialog="dialogQuestion"
      :message="messageDialogQuestion"
      @close="handleClickBtnCancle()"
      @submit="handleSubmitComfirm()"
    />
    <EvidenceStoragePopup
      :dialog.sync="openDialogEvidenceStorage" 
      typeForm="view" 
      :descriptionScope="descriptionScope" 
      :evidences="evidences" 
      :isProcessProduct="true"
    ></EvidenceStoragePopup>

    <NotificationPopup
      :dialog="dialogReJect"
      :message="messageDialogReject"
      @submit="handleClickBtnCancle"
      :confirmText="'OK'"
    />
    <error-popup :dialog="errorPopup" :message="errorMessage" @submit="errorPopup = false" />
  </div>
</template>

<script>
  import DataTable from '@/components/category/data-table';
  import { addThoundSandComma } from '@/utils/convertNumber';
  import { getPrimaryDetail, approvalSupplier } from "@/api/product/emissionsProduct";
  import { ROUTES } from '@/router/constants';
  import { KEYS_CODE } from '@/constants/keyboard';
  import { BLANK_ID } from '@/constants/registerData';
  import { mapActions, mapState } from 'vuex';
  import * as wjcCore from '@mescius/wijmo';
  import { CollectionView } from '@mescius/wijmo';
  import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
  import * as wjGrid from '@mescius/wijmo.grid';
  import { formatValue, $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
  import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
  import { getWidthOfAttachedButton } from '@/utils/multiLanguage';
  import ApprovalPopup from '@/components/dialogs/approval-popup';
  import QuestionPopup from '@/components/dialogs/question-popup';
  import { APPROVAL_TYPE, APPROVAL_STATUS } from '@/constants/registerData';
  import EvidenceStoragePopup from '@/views/emissions/EvidenceStorage/index.vue';
  import { STATUS_FIELD } from '@/constants/status';
  import { getStatusNameById, getColorStatusById } from "@/utils/status";
  import { ORGANIZATIONAL_DATA } from '@/constants/registerData.js';
  import NotificationPopup from '@/components/dialogs/notification-popup.vue';
  import ErrorPopup from '@/components/dialogs/error-popup.vue';
  import { formatDateTimeDataTable } from '@/utils/datetimeFormat';

  export default {
    name: 'ProcessDataLink',

    components: {
      DataTable,
      ApprovalPopup,
      QuestionPopup, 
      EvidenceStoragePopup,
      NotificationPopup,
      ErrorPopup
    },

    data() {
      return {
        breadCrumb: [
          {
            text: this.$t('supplier_products.hyperlink_primary_data'),
            disabled: false,
            href: ROUTES.PRODUCTS_EMISSION + ROUTES.PRIMARY_DATA_MERGE,
          },
          {
            text: this.$t('supplier_products.hyperlink_linked_emissions_data'),
            disabled: true,
            href: ROUTES.PRODUCTS_EMISSION + ROUTES.PRIMARY_DATA_MERGE,
          },
        ],
        listPrimaryColumn: [],
        listPrimaryGrid: null,
        flexGrid: null,
        listPrimaryData: [],
        showTooltip: false,
        processClick: {
          isShowAction: false,
        },
        listLayer :[],
        indexId: '',
        isEmptySource: true,
        labelEmpty: this.$t('process_data_link.table_label_empty'),
        selectedFilterColumn: null,
        dialogApproval: false,
        dialogQuestion: false,
        messageDialogQuestion: this.$t('process_data_link.message_dialog_question'),
        listStatusApproval :[],
        dataTitle: null,
        dataPattern: {},
        scope: null,
        category: null,
        workflow_data: {},
        titlePages: '',
        isHasData: false,
        openDialogEvidenceStorage: false,
        evidences: {},
        approvalPayload:{},
        cfpPrimaryData: {},
        dialogReJect:false,
        messageDialogReject: this.$t('process_data_link.message_dialog_cancle_submit'),
        idProcessEmission: null,
        errorPopup: false,
        errorMessage: ''
      };
    },
    async mounted() {
      await this.updateBreadCrumb(this.breadCrumb);
      await Promise.all([this.handleGetPrimaryDetail()]);
      document.addEventListener('scroll', this.updateActionPosition);
      const tableElement = document.querySelector('.category-table [wj-part=root]');
      tableElement.addEventListener('scroll', this.updateActionPosition);
    },
    computed: {
      ...mapState('commonApp', ['isExpand']),
      ...mapState('registerData', ['isFullScreen']),

      isShowAction() {
        return this.processClick?.isShowAction;
      },
      descriptionScope() {
        return {
          onlyView: true, // only view evidence this screen
          subTitle: this.geSubTitleEvidence
        }
      },
      geSubTitleEvidence() {
        const { cfp_product, cfp_boundary, cfp_process } = this.cfpPrimaryData || {};
        
        const productName = this.$t('cfp_emissions_product.hyperlink_product_name', { productName: cfp_product?.name || '' });
        const boundaryName = cfp_boundary?.name || '';
        const processName = cfp_process?.name || '';

        return [productName, boundaryName, processName].filter(Boolean).join(' > ').trim();
      }

    },

    methods: {
      ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
      ...mapActions('newRegisterData', ['updateIsApproved']),
      ...mapActions('registerData', ['updateDataScope', 'updateDataCategory', 'updateDataMonth', 'updateDuration']),

      initGrid(grid) {
        document.addEventListener('keydown', (event) => {
          if (
            (event.metaKey || event.ctrlKey) &&
            [
              KEYS_CODE.DOWN_ARROW,
              KEYS_CODE.UP_ARROW,
              KEYS_CODE.LEFT_ARROW,
              KEYS_CODE.RIGHT_ARROW,
              KEYS_CODE.ENTER,
            ].includes(event.keyCode)
          ) {
            event.preventDefault();
          }
        });

        grid.hostElement.addEventListener(
          'keydown',
          (event) => {
            if (event.metaKey || event.ctrlKey) {
              if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
                const currentSelection = grid.selection;
                const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
                grid.selection = cellRange;

                // re-select after add more
                setTimeout(() => {
                  grid.selection = cellRange;
                }, 200);
              } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
                const currentSelection = grid.selection;
                grid.selection = new wjGrid.CellRange(0, currentSelection.col);
              } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
                const currentSelection = grid.selection;
                grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
              } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
                const currentSelection = grid.selection;
                grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
              }
            }

            if (event.keyCode === KEYS_CODE.ENTER) {
              if (grid.selection.row === grid.rows.length - 1) {
                const lastClientId = grid.itemsSource.itemCount;

                grid.deferUpdate(() => {
                  grid.itemsSource.addNew(this.blankData(lastClientId + 1));

                  grid.itemsSource.commitNew();
                  grid.itemsSource.clearChanges();
                });
              }
            }
          },
          false,
        );
        grid.hostElement.addEventListener('mouseover', (e) => {
          if (e.target.parentElement.classList.contains('comment-icon')) {
            e.target.src = require(`@/assets/icons/comment_active.svg`);
          }
        });
        grid.hostElement.addEventListener('mouseout', (e) => {
          if (e.target.parentElement.classList.contains('comment-icon')) {
            e.target.src = require(`@/assets/icons/comment.svg`);
          }
        });

        grid.pastingCell.addHandler((s, e) => {
          e.cancel = true;
        });        
        grid.formatItem.addHandler((s, e) => {
          const colBinding = e.panel.columns[e.col].binding;
          if (e.panel == s.cells) {
            const item = s.rows[e.row]._data;
            if (colBinding === 'id') {
              // hide button when status not draft || !owner 
              const isHideButtonDelete = item.status_id !== 0 || typeof item.is_owner !== 'number' || item.is_owner !== 1 
              if (isHideButtonDelete) {
                e.cell.innerHTML = '';
              }
              wjcCore.addClass(e.cell, "is-admin-read-only");
            }
            if (colBinding === 'attach_file') { 
              const rowData = s.rows[e.row].dataItem
              const evidence = rowData?.evidence
              if (!evidence) {
                wjcCore.addClass(e.cell, "btn-disabled");
              }
            }
          }
        })

        grid.beginningEdit.addHandler((sender, event) => {
          event.cancel = true;
        });
        
        // add tooltip
        grid.onSelectionChanged(null);
        this.flexGrid = grid;
      },
      
      async handleGetPrimaryDetail() {
      // TODO: call API here
        this.actionUpdateIsLoadingTable(true)
        const listprimaryResponse = await getPrimaryDetail(this.$route?.params?.processId); 
        this.workflow_data = listprimaryResponse.workflow_data
        if (listprimaryResponse.data.length > 0) {
          this.listPrimaryData = listprimaryResponse.data.map((listPrimarytItem) => {

            const approveInfo =
              this.workflow_data.status === STATUS_FIELD.STATUS_RETURN ||
              this.workflow_data.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ?
              this.workflow_data?.returner[0]?.name : this.workflow_data?.approver?.name;

            const dateApprove =
              this.workflow_data.status === STATUS_FIELD.STATUS_RETURN ||
              this.workflow_data.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ?
              this.workflow_data?.return_at[0]?.return_at : this.workflow_data?.approved_at[0]?.approved_at;
            
              return {
                ...listPrimarytItem,
                organizational_division: ORGANIZATIONAL_DATA[listPrimarytItem.organizational_division],
                company_name: listPrimarytItem?.company_name || null,
                product_name: listPrimarytItem?.name_product|| null,
                product_code: listPrimarytItem?.code || null,
                item_name: listPrimarytItem?.name || null,
                activity_level: addThoundSandComma(listPrimarytItem?.activity_level)|| null,
                activity_level_unit: listPrimarytItem?.activity_level_unit|| null,
                attached: listPrimarytItem?.evidence || null,
                status: this.statusDataDetails(),
                linked_form_name: this.workflow_data?.title,
                approver:  approveInfo || null,
                sent_date: formatDateTimeDataTable(this.workflow_data?.submitted_at[0]?.submitted_at) || null,
                date_approved:formatDateTimeDataTable(dateApprove) || null,
                note: this.workflow_data.note
              };
          });
        }
        const responTitlePage = this.workflow_data.cfp_primary_data
        this.cfpPrimaryData = responTitlePage;
        this.listLayer = listprimaryResponse?.layer || null;
        this.dataPattern = { ...listprimaryResponse,product_flag:true }
        this.titlePages = `${responTitlePage.cfp_product.name} > ${responTitlePage.cfp_product.lot_number} > ${responTitlePage.cfp_boundary.name} > ${responTitlePage.cfp_process.name}`
        this.isHasData = this.dataPattern?.workflow_data.status === 1 && this.dataPattern?.workflow_data.is_active
        this.defineTable();
        this.actionUpdateIsLoadingTable(false)
      },

      initialView() {
        if (this.listPrimaryGrid) {
          this.listPrimaryData = this.listPrimaryData.filter((item) => item.id && item.id !== BLANK_ID);
        }
        const formatItems = ['activity_level', 'activity_level_unit'];
        this.listPrimaryGrid = new CollectionView([...this.listPrimaryData], {
          trackChanges: true,
          sortComparer: (a, b) => {
            if (!formatItems.includes(this.selectedFilterColumn)) return null;
            if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
              const numA = Number(formatValue(a));
              const numB = Number(formatValue(b));
              return numA - numB;
            }
            return null;
          }
        });
        this.flexGrid.columnGroups = this.getListSubmitColumn();
        this.listPrimaryGrid.currentItem = null;
        this.flexGrid.formatItem.addHandler((s, e) => {
          if (s.columns[e.col].binding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
            e.cell.classList.add(this.colorStatusDataDetails());
          }
        });
        this.isEmptySource = this.listPrimaryGrid?.items?.length <= 0;
        setMinMaxSizeColumns(this.flexGrid, this.listPrimaryData);
      },


      getListSubmitColumn() {
        const widthTooltip = 20;
        const layerIndex = {
          1: 'business_name',
          2: 'country',
          3: 'layer_3',
          4: 'layer_4',
          5: 'layer_5',
          6: 'layer_6',
        }
        const layers = this.listLayer?.map((item) => {
          return {
            header: item.layer_name,
            visible: true,
            binding: layerIndex[item.layer_index],
            maxWidth: 980,
            minWidth: getWidthByTextContent(item.layer_name, widthTooltip),
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll : "submitted-color",
            multiLine   : true,
          };
        });
        return [
          {
            header: '#',
            binding: 'id',
            allowSorting: false,
            isReadOnly: true,
            visible: false,
          },
          {
            header: this.$t('process_data_link.table_field_Status'),
            binding: 'status',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_Status'), widthTooltip),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('process_data_link.table_field_link_form_name'),
            binding: 'linked_form_name',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_link_form_name'), widthTooltip),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('process_data_link.table_field_approved'),
            binding: 'approver',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_approved'), widthTooltip),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('process_data_link.table_field_date_sent'),
            binding: 'sent_date',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_date_sent'), widthTooltip),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('process_data_link.table_field_date_approved'),
            binding: 'date_approved',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_date_approved'), widthTooltip),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('b_list_all.table_organizational_information'),
            align: 'center',
            collapseTo: 'organizational_division', 
            columns: [
            {
              header: this.$t('process_data_link.table_field_organizational_division'),
              binding: 'organizational_division',
              minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_organizational_division'), widthTooltip),
              maxWidth: 350,
              allowSorting: false,
              isRequired: false,
              multiLine: true,
              wordWrap: true,
              cssClassAll: 'submitted-color',
            },
            {
              header: this.$t('process_data_link.table_field_company_name'),
              binding: 'company_name',
              minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_company_name'), widthTooltip),
              maxWidth: 980,
              allowSorting: false,
              isRequired: false,
              multiLine: true,
              wordWrap: true,
              cssClassAll: 'submitted-color'
            },
            ...layers,
            ]
          },
          {
            header: this.$t('process_data_link.table_field_link_form_memo_1'),
            binding: 'note',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_link_form_memo_1'), widthTooltip),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('process_data_link.table_field_link_form_memo_2'),
            binding: 'note_2',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_link_form_memo_2'), widthTooltip),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('process_data_link.table_field_product_name'),
            binding: 'product_name',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_product_name'), widthTooltip),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('process_data_link.table_field_product_code'),
            binding: 'product_code',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_product_code'), widthTooltip),
            maxWidth: 200,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('process_data_link.table_field_item_name'),
            binding: 'item_name',
            minWidth: getWidthByTextContent(this.$t('process_data_link.table_field_item_name'), widthTooltip),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            multiLine: true,
            wordWrap: true,
            cssClassAll: 'submitted-color'
          },
          {
            header: this.$t('process_data_link.table_source_unit'),
            align: 'center',
            cssClassAll: 'submitted-color',
            columns: [
              {
                header      : this.$t('process_data_link.table_numeric_value'),
                binding     : "activity_level",
                minWidth    : getWidthByTextContent(this.$t('process_data_link.table_numeric_value'), widthTooltip),
                maxWidth    : 400,
                allowSorting: false,
                isRequired  : false,
                
                wordWrap    : true,
                cssClassAll: 'submitted-color',
                align: 'right',
              },
              {
                header      : this.$t('process_data_link.table_unit_value'),
                binding     : "activity_level_unit",
                minWidth    : getWidthByTextContent(this.$t('process_data_link.table_unit_value'), widthTooltip),
                maxWidth    : 400,
                allowSorting: false,
                isRequired  : false,
                wordWrap    : true,
                cssClassAll: 'submitted-color',
              },
            ],
          },
          {
            header      : this.$t('56_pattern.table_attach_file'),
            binding     : "attach_file",
            cssClass: 'auto-increment',
            minWidth    : getWidthOfAttachedButton(this.$i18n.locale),
            maxWidth    : getWidthOfAttachedButton(this.$i18n.locale),
            allowSorting: false,
            isRequired  : false,
            cssClassAll: "btn-db attach-file hide-filter",
            cellTemplate: (ctx, el) => {
              let text = this.$t('56_pattern.table_attach_file');
              if (ctx.item?.evidence) {
                text = `<div class="file-attached-container">
                  <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
                </div>`
              }
              const button = CellMaker.makeButton({
                text: text,
                click: (e, context) => this.attachFilePopup(e, context)
              })
              return button(ctx, el);
            } 
          }
        ];
      },

      scrollToTop() {
        if (!this.flexGrid) {
          return;
        }
        let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
        this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
      },

      defineTable() {
        this.listPrimaryColumn = this.getListSubmitColumn();
        this.initialView();
      },
  
      onChangeFullScreen(isFullScreen) {
        if (isFullScreen) {
          this.scrollToTop();
        } else {
          this.initialView();

          this.$nextTick(() => {
            this.scrollToTop();
          });
        }
      },
      
      updateActionPosition() {
        this.processClick.isShowAction = false;
      },

      onHandlerSelecteFilterColumn(column) {
        this.selectedFilterColumn = column;
      },

      attachFilePopup(e, context) {
        this.evidences = {};
        this.selectedRow = context.row.index;

        let evidenceData = context?.item?.evidence;
        if (typeof evidenceData === 'string') {
          evidenceData = JSON.parse(evidenceData)
        }

        this.evidences.data = evidenceData;
        this.evidences.value = this.listPrimaryGrid.sourceCollection[this.selectedRow]
          ? this.listPrimaryGrid.sourceCollection[this.selectedRow]['activity_level']
          : null;
        this.openDialogEvidenceStorage = true;
      },

      statusDataDetails() {
        let status = getStatusNameById(this.workflow_data?.status);

        if(this.workflow_data?.status === STATUS_FIELD.STATUS_SUBMIT ) {
         status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
        }

        if(this.workflow_data?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED ) {
          status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
        }
        return status;
      },
      colorStatusDataDetails() {
        if( this.workflow_data?.status === STATUS_FIELD.STATUS_SUBMIT ) {
          if(!this.workflow_data?.is_active) {
            return `color-${getColorStatusById(STATUS_FIELD.STATUS_REPRENSENT_APPROVED)}`;
          }
          return `color-${getColorStatusById(STATUS_FIELD.STATUS_SUBMIT_NON_USER)}`;
        }
        
        if(this.workflow_data?.status === STATUS_FIELD.STATUS_RETURN && !this.workflow_data?.is_active) {
          return `color-${getColorStatusById(STATUS_FIELD.STATUS_REPRENSENT_APPROVED)}`;
        }
        return `color-${getColorStatusById(this.workflow_data?.status)}`;
      },

      onHandleActionTable () {
        this.dialogApproval = true
        if (this.dataPattern.workflow_data.is_approver === 1) {
          this.listStatusApproval = [
            {
              id: APPROVAL_TYPE.APPROVE,
              name: this.$t("register_data.radio_approve"),
              description: this.$t("register_data.description_approve"),
            },
            {
              id: APPROVAL_TYPE.REJECT,
              name: this.$t("register_data.radio_reject"),
              description: this.$t("register_data.description_reject"),
            },
          ];
        } else {
          this.listStatusApproval = [
            {
              id: APPROVAL_TYPE.APPROVE,
              name: this.$t("register_data.radio_proxy_approve"),
              description: this.$t("register_data.description_proxy_approve"),
            },
            {
              id: APPROVAL_TYPE.REJECT,
              name: this.$t("register_data.radio_proxy_reject"),
              description: this.$t("register_data.description_proxy_reject"),
            },
          ];
        }
      },

      approvalDataHandler (approvalFormData) {
        let status = null;
        if (approvalFormData.status === APPROVAL_TYPE.APPROVE) {
          status =
            this.dataPattern.workflow_data.is_approver === 1
              ? APPROVAL_STATUS.APPROVER_APPROVAL
              : APPROVAL_STATUS.APPROVAL;
        } else {
          status =
            this.dataPattern.workflow_data.is_approver === 1 ? APPROVAL_STATUS.APPROVER_RETURN : APPROVAL_STATUS.RETURN;
        }
        this.approvalPayload = {
          id: this.dataPattern.workflow_data.id,
          status: status,
          comment: approvalFormData?.comment?.length > 255 ? approvalFormData.comment.substr(0, 255) : approvalFormData.comment,
        };
        this.handleSubmitApproval()
      },

      handleSubmitApproval () {
        approvalSupplier('approve',this.approvalPayload)
        .then((res) => {
          this.idProcessEmission = res.id_process_emission
          if (this.approvalPayload.status === APPROVAL_STATUS.APPROVER_APPROVAL || this.approvalPayload.status === APPROVAL_STATUS.APPROVAL) {
            this.dialogQuestion = true
          } else {
            this.dialogReJect = true
          }
        })
        .catch((error) => {
          this.errorPopup = true;
          this.errorMessage = error.errors.join('\n');
          return error
        }).finally(() => {
          this.dialogApproval = false
        })
      },

      getUrlIcon() {
        return require(`@/assets/images/registerData/scope${this.scope}category${this.category}.svg`);
      },

      handleSubmitComfirm () {
        this.$router.push({ name: "RegisterEmissions", params: { processId: this.idProcessEmission }, query: { ...this.$route.query, type:'approval'}}).catch(() => {});
      },

      handleClickBtnCancle () {
        this.$router.push( ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCT_PRIMARY_DATA_MERGE)
        this.dialogQuestion = false
        this.dialogReJect = false
      }
    },
  };
</script>

<style scoped lang="scss" src="./index.scss"></style>
